.Contact-img {
  height: 300px;
  width: 100%;
}

.Contact-content-container {
  flex: 1;
}

.Contact-content {
  background-color: #ffffff;
  margin-top: -30px;
  margin-right: 50px;
  padding: 50px 50px 20px;
}

.Contact-title {
  text-align: center;
  margin-top: 0px;
}

.Contact-group {
  margin-bottom: 30px;
}

.Contact-row {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Contact-subtitle {
  text-align: center;
  font-weight: 700;
}

.Contact-info {
  text-align: center;
}

@media only screen and (min-width: 850px) {
  .Contact-container {
    display: flex;
    flex-direction: row;
  }
  .Contact-img {
    flex: 1.5;
    height: 90vh;
    min-height: 750px;
  }
  .Contact-border-box {
    margin-top: 70px;
    margin-right: 100px;
    margin-left: -150px;
    padding-bottom: 30px;
    border: 2px solid var(--color-accent-light);
  }
  .Contact-content {
    margin-right: -50px;
    margin-left: 50px;
    padding-left: 75px;
  }
  .Contact-title {
    text-align: left;
  }
  .Contact-row {
    flex-direction: row;
    align-items: flex-start;
  }
  .Contact-icon {
    margin-top: 10px;
    margin-right: 25px;
  }
  .Contact-subtitle {
    text-align: left;
  }
  .Contact-info {
    text-align: left;
  }
  .mail-icon {
    margin-top: 11px
  }
}

@media only screen and (min-width: 1300px) {
  .Contact-img {
    flex: 2;
  }
  .Contact-content {
    padding-left: 100px;
  }
}