.Menu {
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  position: absolute;
  background-color: #ffffff;
  border-left: 1px solid #d9d9d9;
  box-sizing: border-box;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
  will-change: transform;
  z-index: 100;
}

.Menu-icon-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 15px;
  cursor: pointer;
}

.Menu-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.show-menu {
  -webkit-transform: none;
  transform: none;
}

@media only screen and (min-width: 1000px) {
  .show-menu {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}