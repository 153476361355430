.Quote {
  flex: 1;
  background-color: #ffffff;
}

.Quote h2 {
  text-align: center;
  margin: 0px;
  padding: 45px 30px 15px;
}

.Quote-content {
  display: flex;
  justify-content: center;
  padding: 0px 20px;
}

.form {
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 630px;
  margin: 30px 0;
}

.how-container {
  margin-bottom: 10px;
  align-items: center;
}

.how-label {
  flex: 1.5;
  margin: 0px;
}

.how-picker-container {
  flex: 1;
  display: flex;
  align-items: center;
}

.how-picker {
  flex: 1;
  margin-left: 20px;
  font-size: 14px;
  font-family: 'Cambay', sans-serif;
  border-radius: 0;
  border-color: var(--color-secondary);
  background-color: var(--color-primary);
  padding: 8px 35px 8px 10px;
	-moz-appearance: none;
	-webkit-appearance: none;
  appearance: none;
	background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
	background-repeat: no-repeat, repeat;
	background-position: right 10px top 50%, 0 0;
	background-size: .65em auto, 100%;
}

.how-picker:hover {
  cursor: pointer;
}

.picker-placeholder {
  color: #757575;
}

.picker-option {
  margin: 20px;
}

.input {
  height: 42px;
  font-size: 14px;
  font-family: 'Cambay', sans-serif;
  padding: 0px 10px;
  margin-bottom: 2px;
}

.text-area {
  height: 100px;
  margin-bottom: 0;
  padding-top: 10px;
}

.form-box {
  background-color: var(--color-primary);
  border-style: solid;
  border-color: var(--color-secondary);
  border-width: thin;
}

.error-box {
  border-color: red;
}

.error-container {
  height: 24px;
}

.error-message {
  color: red;
  font-size: 12px;
  margin: 0;
}

.how-error-container {
  min-height: 24px;
}

.how-error {
  text-align: right;
}

.Quote-button {
  height: 48px;
  width: 132px;
  margin: 40px 0px 20px 0px;
}

@media only screen and (min-width: 600px) {
  .how-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
  }
  .how-label {
    flex: none;
  }
  .how-picker-container {
    flex: none;
    flex-direction: row;
  }
  .how-picker {
    margin-right: 10px;
  }
}