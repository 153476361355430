.Footer {
  height: 45px;
  padding: 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-secondary);
  color: var(--color-primary);
}

.Footer-group p {
  margin: 0px;
  font-size: 12px;
}

.Footer-divider {
  display: flex;
  margin: 0px 10px;
}

.privacy-policy {
  margin-right: 10px;
}

@media only screen and (min-width: 800px) {
  .Footer-group p {
    font-size: 14px;
  }
}