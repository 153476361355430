@import url('https://fonts.googleapis.com/icon?family=Material+Icons+Outlined');

:root {
  --navbar-height: 90px;
  --navbar-mobile-height: 70px;
  --color-primary: #f9f8f5;
  --color-secondary: #3B2A24;
  --color-accent: #916235;
  --color-accent-light: #ead8c7;
  --color-text: #3B2A24;
  --color-dark: #4C4C4C;
}

.App {
  display: flex;
  flex-direction: column;
  width: 100vw;
  max-width: 100vw;
  min-height: 100vh;
  background-color: var(--color-primary);
  color: var(--color-text);
}

.navbar-spacer {
  height: var(--navbar-mobile-height);
}

p {
  font-size: 18px;
}

.header-title {
  font-family: 'Lora', serif;
  font-size: 60px;
  font-weight: 500;
}

.title {
  font-family: 'Lora', serif;
  font-size: 44px;
  font-weight: 500;
}

.subtitle {
  font-family: 'Lora', serif;
  font-size: 36px;
  font-weight: 500;
}

.bold {
  font-weight: 700;
}

.no-scroll {
  overflow: hidden;
  position:fixed;
}

.row {
  display: flex;
  flex-direction: row;
}

.column {
  flex-direction: column;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.align-right {
  display: flex;
  justify-content: flex-end;
}

.divider {
  width: 1px;
  height: 35px;
  background-color: #000000;
}

.horizontal-divider {
  width: '100%';
  height: 1px;
  background-color: #000000;
}

.img-overlay {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,0.35);
}

.outline-button {
  text-align: 'center';
  display: 'inline';
  font-size: 18px;
  border-radius: 3px;
  padding: 15px 30px;
}

.black-button {
  border: 1px solid var(--color-secondary);
  color: var(--color-text);
  background-color: transparent;
}

.black-button:hover {
  border-color: var(--color-accent);
  color: var(--color-accent);
  cursor: pointer;
}

.disabled-button {
  opacity: 0.5;
}

.shadow {
  box-shadow: 0px 2px 34px 0px rgb(0 0 0 / 50%);
}

.material-icons-outlined.md-18 { font-size: 18px; }
.material-icons-outlined.md-24 { font-size: 24px; }
.material-icons-outlined.md-36 { font-size: 36px; }
.material-icons-outlined.md-48 { font-size: 48px; }
.material-icons-outlined.md-60 { font-size: 60px; }

@media only screen and (min-width: 1000px) {
  .navbar-spacer {
    height: var(--navbar-height);
  }
  .header-title {
    font-size: 90px;
  }
  .title {
    font-size: 60px;
  }
  .subtitle {
    font-size: 48px;
  }
}