.Projects-header-img {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Projects-label-container {
  padding: 35px 35px 75px;
  text-align: center;
}

.Projects-img {
  height: 500px;
  width: '100%';
  margin-bottom: 10px;
  transition: all 0.75s ease;
}

.Projects-img:hover {
  cursor: pointer;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.Projects p {
  margin-bottom: 0px;
}

.Projects-subtitle {
  font-weight: 600;
}

.Projects-description {
  margin-top: 0px;
}

.grid1 {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}

.ramona-label {
  grid-column: 2 / 3;
  grid-row: 1 / 10;
}

.ramona {
  grid-column: 4 / 9;
  grid-row: 1 / 10;
}

.ne {
  grid-column: 2 / 5;
  grid-row: 11 / 20;
}

.ne-label {
  grid-column: 6 / 8;
  grid-row: 11 / 20;
}

.chavez-label {
  grid-column: 2 / 3;
  grid-row: 21 / 30;
}

.chavez {
  grid-column: 4 / 9;
  grid-row: 21 / 30;
}

.lites {
  grid-column: 2 / 6;
  grid-row: 31 / 40;
}

.lites-label {
  grid-column: 7 / 8;
  grid-row: 31 / 40;
}

.windsor-label {
  grid-column: 2 / 3;
  grid-row: 41 / 50;
}

.windsor {
  grid-column: 4 / 9;
  grid-row: 41 / 50;
}

.kitchen {
  grid-column: 2 / 6;
  grid-row: 51 / 60;
}

.kitchen-label {
  grid-column: 7 / 8;
  grid-row: 51 / 60;
}

.bathroom-label {
  grid-column: 2 / 3;
  grid-row: 61 / 70;
}

.bathroom {
  grid-column: 4 / 9;
  grid-row: 61 / 70;
}

@media only screen and (min-width: 1100px) {
  .Projects {
    margin-bottom: 80px;
  }
  .Projects-header-img {
    justify-content: flex-start;
    align-items: flex-end;
  }
  .Projects-header-title {
    margin: 8%;
  }
  .grid1 {
    display: grid;
    grid-template-columns: 1fr 10fr 1fr 1fr 1fr 1fr 10fr 1fr;
    grid-auto-rows: 80px;
    margin-top: 80px;
  }
  .Projects-title {
    margin-top: 0px;
  }
  .Projects-img {
    height: auto;
    margin-bottom: 0px;
  }
  .Projects-label-container {
    text-align: left;
  }
}