.Navbar {
  position: fixed;
  top: 0px;
  z-index: 10;
  height: var(--navbar-mobile-height);
  width: 100%;
  justify-content: space-between;
  -moz-box-sizing: border-box;
       box-sizing: border-box;
  padding-right: 10px;
  padding-left: 10px;
  transition: top 1s ease-in-out, background-color 1s ease-in-out;
}

.Navbar-up {
  top: 0px;
  box-shadow: 1px 1px 2px #f2f2f2;
  background-color: rgba(255,255,255,1);
}

.Navbar-down {
  top: -100px;
}

.Navbar-row {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
}

.Navbar-logo {
  height: 48px;
  width: 48px;
  margin-right: 15px;
}

.Navbar-name {
  color:var(--color-text);
  font-size: 24px;
}

.Navbar-link {
  margin-right: 20px;
  margin-left: 20px;
  text-decoration: none;
  font-size: 16px;
  letter-spacing: 1.5px;
  color:var(--color-text);
}

.Navbar-link.active {
  color: var(--color-accent);
}

.Navbar-link:after {
  content: '';
  display: block;
  margin: auto;
  height: 2px;
  width: 0px;
  background: transparent;
  transition: width .5s ease, background-color .5s ease;
}

.navbar {
  display: none;
}

a:visited {
  color:var(--color-text);
}

.mobile-nav {
  display: flex;
  cursor: pointer;
}

@media only screen and (min-width: 1000px) {
  .Navbar {
    height: var(--navbar-height);
    padding-right: 25px;
    padding-left: 25px;
  }
  .Navbar-logo {
    height: 68px;
    width: 68px;
    margin-right: 20px;
  }
  .Navbar-name {
    font-size: 28px;
  }
  .mobile-nav {
    display: none;
  }
  .navbar {
    display: flex;
  }
  .Navbar-link:hover:after, .Navbar-link.active:after {
    width: 100%;
    background: var(--color-accent);
  }
}