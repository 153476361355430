.About-row {
  display: flex;
  flex-direction: column;
  padding-bottom: 25px;
}

.About-text-container {
  margin: 0px 50px;
}

.About-title {
  text-align: center;
}

.About-intro-row {
  min-height: 100vh;
  background-size: cover;
}

.About-intro-img {
  height: 500px;
  width: 100%;
}

.About-trust-img {
  height: 400px;
  width: 100%;
}

.About-honesty-img {
  height: 500px;
  width: 80%;
  margin: auto;
}

.About-craft-img {
  height: 400px;
  width: 100%;
}

@media only screen and (min-width: 1000px) {
  .About-row {
    flex-direction: row;
    align-items: center;
    padding: 10px 50px 100px;
  }
  .About-img {
    margin: 0px 50px;
  }
  .About-text-container {
    flex: 1;
    margin-bottom: 50px;
  }
  .About-title {
    text-align: left;
  }
  .About-intro-row {
    padding-bottom: var(--navbar-height);
    flex-direction: row-reverse;
  }
  .About-intro-img {
    margin-top: var(--navbar-height);
    min-height: 700px;
    width: 40%;
  }
  .About-trust-img {
    min-height: 650px;
    width: 45%;
  }
  .About-honesty-row {
    flex-direction: row-reverse;
  }
  .About-honesty-img {
    min-height: 700px;
    width: 43%;
  }
  .About-craft-img {
    min-height: 650px;
    width: 45%;
  }
}