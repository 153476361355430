@import url(https://fonts.googleapis.com/icon?family=Material+Icons+Outlined);
html, body {
  overflow-x: hidden;
}

body {
  position: relative;
  margin: 0;
  font-family: 'Cambay', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.Navbar {
  position: fixed;
  top: 0px;
  z-index: 10;
  height: var(--navbar-mobile-height);
  width: 100%;
  justify-content: space-between;
  box-sizing: border-box;
  padding-right: 10px;
  padding-left: 10px;
  transition: top 1s ease-in-out, background-color 1s ease-in-out;
}

.Navbar-up {
  top: 0px;
  box-shadow: 1px 1px 2px #f2f2f2;
  background-color: rgba(255,255,255,1);
}

.Navbar-down {
  top: -100px;
}

.Navbar-row {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
}

.Navbar-logo {
  height: 48px;
  width: 48px;
  margin-right: 15px;
}

.Navbar-name {
  color:var(--color-text);
  font-size: 24px;
}

.Navbar-link {
  margin-right: 20px;
  margin-left: 20px;
  text-decoration: none;
  font-size: 16px;
  letter-spacing: 1.5px;
  color:var(--color-text);
}

.Navbar-link.active {
  color: var(--color-accent);
}

.Navbar-link:after {
  content: '';
  display: block;
  margin: auto;
  height: 2px;
  width: 0px;
  background: transparent;
  transition: width .5s ease, background-color .5s ease;
}

.navbar {
  display: none;
}

a:visited {
  color:var(--color-text);
}

.mobile-nav {
  display: flex;
  cursor: pointer;
}

@media only screen and (min-width: 1000px) {
  .Navbar {
    height: var(--navbar-height);
    padding-right: 25px;
    padding-left: 25px;
  }
  .Navbar-logo {
    height: 68px;
    width: 68px;
    margin-right: 20px;
  }
  .Navbar-name {
    font-size: 28px;
  }
  .mobile-nav {
    display: none;
  }
  .navbar {
    display: flex;
  }
  .Navbar-link:hover:after, .Navbar-link.active:after {
    width: 100%;
    background: var(--color-accent);
  }
}
.Footer {
  height: 45px;
  padding: 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-secondary);
  color: var(--color-primary);
}

.Footer-group p {
  margin: 0px;
  font-size: 12px;
}

.Footer-divider {
  display: flex;
  margin: 0px 10px;
}

.privacy-policy {
  margin-right: 10px;
}

@media only screen and (min-width: 800px) {
  .Footer-group p {
    font-size: 14px;
  }
}
.Menu {
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  position: absolute;
  background-color: #ffffff;
  border-left: 1px solid #d9d9d9;
  box-sizing: border-box;
  transform: translateX(100%);
  transition: all 0.25s;
  will-change: transform;
  z-index: 100;
}

.Menu-icon-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 15px;
  cursor: pointer;
}

.Menu-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.show-menu {
  transform: none;
}

@media only screen and (min-width: 1000px) {
  .show-menu {
    transform: translateX(100%);
  }
}
.Quote {
  flex: 1 1;
  background-color: #ffffff;
}

.Quote h2 {
  text-align: center;
  margin: 0px;
  padding: 45px 30px 15px;
}

.Quote-content {
  display: flex;
  justify-content: center;
  padding: 0px 20px;
}

.form {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  max-width: 630px;
  margin: 30px 0;
}

.how-container {
  margin-bottom: 10px;
  align-items: center;
}

.how-label {
  flex: 1.5 1;
  margin: 0px;
}

.how-picker-container {
  flex: 1 1;
  display: flex;
  align-items: center;
}

.how-picker {
  flex: 1 1;
  margin-left: 20px;
  font-size: 14px;
  font-family: 'Cambay', sans-serif;
  border-radius: 0;
  border-color: var(--color-secondary);
  background-color: var(--color-primary);
  padding: 8px 35px 8px 10px;
	-moz-appearance: none;
	-webkit-appearance: none;
  appearance: none;
	background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
	background-repeat: no-repeat, repeat;
	background-position: right 10px top 50%, 0 0;
	background-size: .65em auto, 100%;
}

.how-picker:hover {
  cursor: pointer;
}

.picker-placeholder {
  color: #757575;
}

.picker-option {
  margin: 20px;
}

.input {
  height: 42px;
  font-size: 14px;
  font-family: 'Cambay', sans-serif;
  padding: 0px 10px;
  margin-bottom: 2px;
}

.text-area {
  height: 100px;
  margin-bottom: 0;
  padding-top: 10px;
}

.form-box {
  background-color: var(--color-primary);
  border-style: solid;
  border-color: var(--color-secondary);
  border-width: thin;
}

.error-box {
  border-color: red;
}

.error-container {
  height: 24px;
}

.error-message {
  color: red;
  font-size: 12px;
  margin: 0;
}

.how-error-container {
  min-height: 24px;
}

.how-error {
  text-align: right;
}

.Quote-button {
  height: 48px;
  width: 132px;
  margin: 40px 0px 20px 0px;
}

@media only screen and (min-width: 600px) {
  .how-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
  }
  .how-label {
    flex: none;
  }
  .how-picker-container {
    flex: none;
    flex-direction: row;
  }
  .how-picker {
    margin-right: 10px;
  }
}
.Home-img {
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: 50% 35%;
  transition: background-image 2s ease-in-out;
}

.Home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 40px 60px;
}

.Home-text-container {
  text-align: center;
  margin-bottom: 60px;
}

.Home-title {
  margin-bottom: 40px;
}

.Home-tiles {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Home-tile {
  display: flex;
  align-items: flex-end;
  margin-bottom: 15px;
}

.Home-tile-text {
  color: #ffffff;
  border: #ffffff 3px solid;
  padding: 30px 20px;
  min-width: 35%;
  text-align: center;
}

.Home-about {
  height: 350px;
  width: 100%;
}

.Home-projects {
  height: 350px;
  width: 100%;
}

.Home-nav-link {
  text-decoration: none;
}

@media only screen and (min-width: 1000px) {
  .Home-text-container {
    max-width: 950px;
    margin: 50px 0px 120px;
  }
  .Home-title {
    font-size: 64px;
  }
  .Home-text {
    font-size: 20px;
  }
  .Home-tiles {
    flex-direction: row;
    width: 100%;
  }
  .Home-nav-link {
    flex: 1 1;
    margin: 5px;
  }
  .Home-tile {
    height: 90vh;
  }
  
}
.About-row {
  display: flex;
  flex-direction: column;
  padding-bottom: 25px;
}

.About-text-container {
  margin: 0px 50px;
}

.About-title {
  text-align: center;
}

.About-intro-row {
  min-height: 100vh;
  background-size: cover;
}

.About-intro-img {
  height: 500px;
  width: 100%;
}

.About-trust-img {
  height: 400px;
  width: 100%;
}

.About-honesty-img {
  height: 500px;
  width: 80%;
  margin: auto;
}

.About-craft-img {
  height: 400px;
  width: 100%;
}

@media only screen and (min-width: 1000px) {
  .About-row {
    flex-direction: row;
    align-items: center;
    padding: 10px 50px 100px;
  }
  .About-img {
    margin: 0px 50px;
  }
  .About-text-container {
    flex: 1 1;
    margin-bottom: 50px;
  }
  .About-title {
    text-align: left;
  }
  .About-intro-row {
    padding-bottom: var(--navbar-height);
    flex-direction: row-reverse;
  }
  .About-intro-img {
    margin-top: var(--navbar-height);
    min-height: 700px;
    width: 40%;
  }
  .About-trust-img {
    min-height: 650px;
    width: 45%;
  }
  .About-honesty-row {
    flex-direction: row-reverse;
  }
  .About-honesty-img {
    min-height: 700px;
    width: 43%;
  }
  .About-craft-img {
    min-height: 650px;
    width: 45%;
  }
}
.Contact-img {
  height: 300px;
  width: 100%;
}

.Contact-content-container {
  flex: 1 1;
}

.Contact-content {
  background-color: #ffffff;
  margin-top: -30px;
  margin-right: 50px;
  padding: 50px 50px 20px;
}

.Contact-title {
  text-align: center;
  margin-top: 0px;
}

.Contact-group {
  margin-bottom: 30px;
}

.Contact-row {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Contact-subtitle {
  text-align: center;
  font-weight: 700;
}

.Contact-info {
  text-align: center;
}

@media only screen and (min-width: 850px) {
  .Contact-container {
    display: flex;
    flex-direction: row;
  }
  .Contact-img {
    flex: 1.5 1;
    height: 90vh;
    min-height: 750px;
  }
  .Contact-border-box {
    margin-top: 70px;
    margin-right: 100px;
    margin-left: -150px;
    padding-bottom: 30px;
    border: 2px solid var(--color-accent-light);
  }
  .Contact-content {
    margin-right: -50px;
    margin-left: 50px;
    padding-left: 75px;
  }
  .Contact-title {
    text-align: left;
  }
  .Contact-row {
    flex-direction: row;
    align-items: flex-start;
  }
  .Contact-icon {
    margin-top: 10px;
    margin-right: 25px;
  }
  .Contact-subtitle {
    text-align: left;
  }
  .Contact-info {
    text-align: left;
  }
  .mail-icon {
    margin-top: 11px
  }
}

@media only screen and (min-width: 1300px) {
  .Contact-img {
    flex: 2 1;
  }
  .Contact-content {
    padding-left: 100px;
  }
}
.Projects-header-img {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Projects-label-container {
  padding: 35px 35px 75px;
  text-align: center;
}

.Projects-img {
  height: 500px;
  width: '100%';
  margin-bottom: 10px;
  transition: all 0.75s ease;
}

.Projects-img:hover {
  cursor: pointer;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.Projects p {
  margin-bottom: 0px;
}

.Projects-subtitle {
  font-weight: 600;
}

.Projects-description {
  margin-top: 0px;
}

.grid1 {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}

.ramona-label {
  grid-column: 2 / 3;
  grid-row: 1 / 10;
}

.ramona {
  grid-column: 4 / 9;
  grid-row: 1 / 10;
}

.ne {
  grid-column: 2 / 5;
  grid-row: 11 / 20;
}

.ne-label {
  grid-column: 6 / 8;
  grid-row: 11 / 20;
}

.chavez-label {
  grid-column: 2 / 3;
  grid-row: 21 / 30;
}

.chavez {
  grid-column: 4 / 9;
  grid-row: 21 / 30;
}

.lites {
  grid-column: 2 / 6;
  grid-row: 31 / 40;
}

.lites-label {
  grid-column: 7 / 8;
  grid-row: 31 / 40;
}

.windsor-label {
  grid-column: 2 / 3;
  grid-row: 41 / 50;
}

.windsor {
  grid-column: 4 / 9;
  grid-row: 41 / 50;
}

.kitchen {
  grid-column: 2 / 6;
  grid-row: 51 / 60;
}

.kitchen-label {
  grid-column: 7 / 8;
  grid-row: 51 / 60;
}

.bathroom-label {
  grid-column: 2 / 3;
  grid-row: 61 / 70;
}

.bathroom {
  grid-column: 4 / 9;
  grid-row: 61 / 70;
}

@media only screen and (min-width: 1100px) {
  .Projects {
    margin-bottom: 80px;
  }
  .Projects-header-img {
    justify-content: flex-start;
    align-items: flex-end;
  }
  .Projects-header-title {
    margin: 8%;
  }
  .grid1 {
    display: grid;
    grid-template-columns: 1fr 10fr 1fr 1fr 1fr 1fr 10fr 1fr;
    grid-auto-rows: 80px;
    margin-top: 80px;
  }
  .Projects-title {
    margin-top: 0px;
  }
  .Projects-img {
    height: auto;
    margin-bottom: 0px;
  }
  .Projects-label-container {
    text-align: left;
  }
}
:root {
  --navbar-height: 90px;
  --navbar-mobile-height: 70px;
  --color-primary: #f9f8f5;
  --color-secondary: #3B2A24;
  --color-accent: #916235;
  --color-accent-light: #ead8c7;
  --color-text: #3B2A24;
  --color-dark: #4C4C4C;
}

.App {
  display: flex;
  flex-direction: column;
  width: 100vw;
  max-width: 100vw;
  min-height: 100vh;
  background-color: #f9f8f5;
  background-color: var(--color-primary);
  color: #3B2A24;
  color: var(--color-text);
}

.navbar-spacer {
  height: 70px;
  height: var(--navbar-mobile-height);
}

p {
  font-size: 18px;
}

.header-title {
  font-family: 'Lora', serif;
  font-size: 60px;
  font-weight: 500;
}

.title {
  font-family: 'Lora', serif;
  font-size: 44px;
  font-weight: 500;
}

.subtitle {
  font-family: 'Lora', serif;
  font-size: 36px;
  font-weight: 500;
}

.bold {
  font-weight: 700;
}

.no-scroll {
  overflow: hidden;
  position:fixed;
}

.row {
  display: flex;
  flex-direction: row;
}

.column {
  flex-direction: column;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.align-right {
  display: flex;
  justify-content: flex-end;
}

.divider {
  width: 1px;
  height: 35px;
  background-color: #000000;
}

.horizontal-divider {
  width: '100%';
  height: 1px;
  background-color: #000000;
}

.img-overlay {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,0.35);
}

.outline-button {
  text-align: 'center';
  display: 'inline';
  font-size: 18px;
  border-radius: 3px;
  padding: 15px 30px;
}

.black-button {
  border: 1px solid #3B2A24;
  border: 1px solid var(--color-secondary);
  color: #3B2A24;
  color: var(--color-text);
  background-color: transparent;
}

.black-button:hover {
  border-color: #916235;
  border-color: var(--color-accent);
  color: #916235;
  color: var(--color-accent);
  cursor: pointer;
}

.disabled-button {
  opacity: 0.5;
}

.shadow {
  box-shadow: 0px 2px 34px 0px rgb(0 0 0 / 50%);
}

.material-icons-outlined.md-18 { font-size: 18px; }
.material-icons-outlined.md-24 { font-size: 24px; }
.material-icons-outlined.md-36 { font-size: 36px; }
.material-icons-outlined.md-48 { font-size: 48px; }
.material-icons-outlined.md-60 { font-size: 60px; }

@media only screen and (min-width: 1000px) {
  .navbar-spacer {
    height: 90px;
    height: var(--navbar-height);
  }
  .header-title {
    font-size: 90px;
  }
  .title {
    font-size: 60px;
  }
  .subtitle {
    font-size: 48px;
  }
}
