.Home-img {
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: 50% 35%;
  -webkit-transition: background-image 2s ease-in-out;
  transition: background-image 2s ease-in-out;
}

.Home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 40px 60px;
}

.Home-text-container {
  text-align: center;
  margin-bottom: 60px;
}

.Home-title {
  margin-bottom: 40px;
}

.Home-tiles {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Home-tile {
  display: flex;
  align-items: flex-end;
  margin-bottom: 15px;
}

.Home-tile-text {
  color: #ffffff;
  border: #ffffff 3px solid;
  padding: 30px 20px;
  min-width: 35%;
  text-align: center;
}

.Home-about {
  height: 350px;
  width: 100%;
}

.Home-projects {
  height: 350px;
  width: 100%;
}

.Home-nav-link {
  text-decoration: none;
}

@media only screen and (min-width: 1000px) {
  .Home-text-container {
    max-width: 950px;
    margin: 50px 0px 120px;
  }
  .Home-title {
    font-size: 64px;
  }
  .Home-text {
    font-size: 20px;
  }
  .Home-tiles {
    flex-direction: row;
    width: 100%;
  }
  .Home-nav-link {
    flex: 1;
    margin: 5px;
  }
  .Home-tile {
    height: 90vh;
  }
  
}